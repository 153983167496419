import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private http: HttpClient,
  ) { }

  //private Url = environment.httpText + environment.server +':'+environment.port+environment.containerName+'/users';
    private Url = environment.httpText + environment.apiServer + 'statistics';

    getDataCondition(){ // 데이터 현황 조회
      var url = `${this.Url}/conditions`
  
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /statistics/conditions`) )
      );
    }


    getDataTrans(unit : string){ // 데이터 제공량/ 판매량 추이
      var url = `${this.Url}/trend`
      let params = new HttpParams()
      .set("unit", unit)

      return this.http.get<any>( url, {params : params}).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /statistics/trend`) )
      );
    }



    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
