import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter';
import { PurchasesCategoriesSummary } from '../data-object/PurchasesCategoriesSummary';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  constructor(
    private http: HttpClient,
  ) { }

    private Url = environment.httpText + environment.apiServer + 'purchases';


    getPurchases(filter : Filter){ // 구매 기록 리스트 조회
      var url = `${this.Url}`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.name != null){
        httpParams = httpParams.set('name', filter.name)
      }
  
      return this.http.get( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /purchases`) )
      );
    }

    createPurchase(categoryCode : string){ // 연구 데이터 구매
      var url = `${this.Url}`;
      let parameter = {
        categoryCode : categoryCode
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /purchases`) )
      );
    }

    getPurchaseCategory(filter : Filter) : Observable<PurchasesCategoriesSummary>{ // 구매한 데이터의 카테고리 정보 및 데이터 수 조회
      var url = `${this.Url}/categories/summary`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.largeCategoryName != null){
        httpParams = httpParams.set('largeCategoryName', filter.largeCategoryName)
      }

      if(filter.mediumCategoryName != null){
        httpParams = httpParams.set('mediumCategoryName', filter.mediumCategoryName)
      }

      if(filter.providerName != null){
        httpParams = httpParams.set('providerName', filter.providerName)
      }
  
      return this.http.get<PurchasesCategoriesSummary>( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<PurchasesCategoriesSummary>(`GET /purchases/categories/summary`) )
      );
    }


    
    updateSalesData(categoryCodeList : string[], documentIdList : string[] ){ // 데이터 판매 설정
      var url = `${this.Url}/sales`;
      let parameter = {
        categoryCodeList: categoryCodeList,
        documentIdList: documentIdList
      }
  
      return this.http.put( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`PUT /purchases/sales`) )
      );
    }

    getPurchase(purchaseId : string ){ // 구매 정보 조회
      var url = `${this.Url}/${purchaseId}`;
  
      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /purchases/{purchaseId}`) )
      );
    }

    deletePurchase(purchaseId : string ){ // 구매 정보 조회  
      
      var url = `${this.Url}/${purchaseId}`;
  
      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /purchases/{purchaseId}`) )
      );
    }


    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
