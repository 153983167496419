import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor(
    private http: HttpClient,
  ) { }

    private Url = environment.httpText + environment.apiServer + 'metadata';

    getCategories(filter : Filter){ //카테고리 목록 조회
      var url = `${this.Url}/categories`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams =  httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams =  httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams =  httpParams.set('offset', filter.offset)
      }

      if(filter.isAttached != null){
        httpParams =  httpParams.set('isAttached', filter.isAttached)
      }

      if(filter.name != null){
        httpParams = httpParams.set('name', filter.name)
      }

      if(filter.code != null){
        httpParams = httpParams.set('code', filter.code)
      }

      return this.http.get<any>( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /metadata/categories`) )
      );
    }

    createCategories(){ //카테고리 목록 조회
      var url = `${this.Url}/categories`;
      
      let parameter = {
        categoryCode: "string",
        parentCode: "string",
        category: {
          categoryCode: "string",
          parentCode: "string",
          category: "string",
          displayName: "string",
          summary: "string",
          description: "string",
          depth: "ROOT",
          isAttached: true
        },
        displayName: "string",
        summary: "string",
        description: "string",
        depth: "ROOT",
        isAttached: true
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /metadata/categories`) )
      );
    }

    getCategorie(filter : Filter, categoryCode : string){ //카테고리 정보 및 하위 카테고리 목록 조회 
      var url = `${this.Url}/categories/${categoryCode}`;
      let httpParams = new HttpParams()

/*      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }
*/
      return this.http.get<any>( url, { params: httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /metadata/categories/{categoryCode}`) )
      );
    }

    deleteCategorie(categoryCode : string){ //지정된 카테고리 삭제
      var url = `${this.Url}/categories/${categoryCode}`;
      return this.http.delete<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /metadata/categories/{categoryCode}`) )
      );
    }

    updateCategorie(categoryCode : string){ //지정된 카테고리 업데이트
      var url = `${this.Url}/categories/${categoryCode}`;
      let parameter = {
        parentCode: "string",
        category: {
          categoryCode: "string",
          parentCode: "string",
          category: {
            categoryCode: "string",
            parentCode: "string",
            category: "string",
            displayName: "string",
            summary: "string",
            description: "string",
            depth: "ROOT",
            isAttached: true
          },
          displayName: "string",
          summary: "string",
          description: "string",
          depth: "ROOT",
          isAttached: true
        },
        displayName: "string",
        summary: "string",
        description: "string",
        depth: "ROOT",
        isAttached: true
      }

      return this.http.put<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`PUT /metadata/categories/{categoryCode}`) )
      );
    }

    getCategoryDataSchema(categoryCode : string){ //지정된 카테고리의 데이터 포맷 조회 
      var url = `${this.Url}/categories/${categoryCode}/schemas`;

      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /metadata/categories/{categoryCode}/schemas`) )
      );
    }

    getCategorySubject(filter : Filter,  categoryCode : string){ //지정된 카테고 아래에 있는 Subject목록 조회
      var url = `${this.Url}/${categoryCode}/subjects`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams = httpParams = httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams = httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.subjectName != null){
        httpParams = httpParams = httpParams.set('subjectName', filter.subjectName)
      }

      if(filter.subjectCode != null){
        httpParams = httpParams = httpParams.set('subjectCode', filter.subjectCode)
      }

      
      return this.http.get<any>( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /metadata/{categoryCode}/subjects`) )
      );
    }

    createCategorySubject(categoryCode : string){ //지정된 카테고리 아래에 Subject 생성
      var url = `${this.Url}/${categoryCode}/subjects`;
      let parameter = {
        subjectId: "string",
        category: {
          categoryCode: "string",
          parentCode: "string",
          category: {
            categoryCode: "string",
            parentCode: "string",
            category: "string",
            displayName: "string",
            summary: "string",
            description: "string",
            depth: "ROOT",
            isAttached: true
          },
          displayName: "string",
          summary: "string",
          description: "string",
          depth: "ROOT",
          isAttached: true
        },
        infileIndex: 0,
        subjectCode: "string",
        displayName: "string",
        valueType: "UNKNOWN",
        inspectionTool: "string",
        description: "string",
        reference: "string",
        note: "string"
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /metadata/{categoryCode}/subjects`) )
      );
    }


    getCategorySubjectDetail(categoryCode : string, subjectCode : string){ //지정된 Subject의 상세 정보 조회
      var url = `${this.Url}/${categoryCode}/subjects/${subjectCode}`;

      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /metadata/{categoryCode}/subjects/{subjectCode}`) )
      );
    }

    deleteCategorySubjectDetail(categoryCode : string, subjectCode : string){ //지정된 Subject 삭제
      var url = `${this.Url}/${categoryCode}/subjects/${subjectCode}`;

      return this.http.delete<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /metadata/{categoryCode}/subjects/{subjectCode}`) )
      );
    }

    updateCategorySubjectDetail(categoryCode : string, subjectCode : string){ //지정된 Subject 업데이트
      var url = `${this.Url}/${categoryCode}/subjects/${subjectCode}`;
      let parameter = {
        subjectId: "string",
        category: {
          categoryCode: "string",
          parentCode: "string",
          category: {
            categoryCode: "string",
            parentCode: "string",
            category: "string",
            displayName: "string",
            summary: "string",
            description: "string",
            depth: "ROOT",
            isAttached: true
          },
          displayName: "string",
          summary: "string",
          description: "string",
          depth: "ROOT",
          isAttached: true
        },
        infileIndex: 0,
        displayName: "string",
        valueType: "UNKNOWN",
        inspectionTool: "string",
        description: "string",
        reference: "string",
        note: "string"
      }


      return this.http.put<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`PUT /metadata/{categoryCode}/subjects/{subjectCode}`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
