import { Injectable } from '@angular/core';
import { Router, withInMemoryScrolling } from '@angular/router';
import { HttpClient, HttpContext, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { throwError, catchError, finalize, map, Observable, of, Subject, tap } from 'rxjs';
import { UserService } from '../user.service';
import { environment } from 'src/environments/environment';
import { resourceLimits } from 'worker_threads';


const httpOptions = {
  headers: new HttpHeaders({ 
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin' : '*'
 })
};

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService{
  public showLoader: boolean = false;
  constructor(
    public router: Router,
    private http: HttpClient,
    private userService : UserService

  ) { }



  private Url = 'https://nppdata.suredatalab.kr';
  // private myUrl= 'http://192.168.8.47'
  
  loginErrorMessageSubject = new Subject<string>();
  loginErrorMessage$ = this.loginErrorMessageSubject.asObservable();

  SignIn(url : string, email: string, password: string) : Observable<any> {
    let parameter = {
      login : email,
      password : password
    }

    return this.http.post( `${url}`, Object.entries(parameter).map(e => e.join('=')).join('&'), {
      headers: new HttpHeaders({ 
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin' : '*'
      }), responseType : 'json'} )
      .pipe(
        map((books: any) => {
          console.log(books)
        }),
      tap( data => {
        console.log(data)
        this.userService.getMyProfile().subscribe(result => {

          console.log(result);
          // debugger;
          localStorage.setItem('user', JSON.stringify(result));
          if (result.authorityId == "root") {
            this.router.navigate(['/dataRegistration']);
          } else if (result.authorityId == "user") {
            this.router.navigate(['/dataRegistration']);
          }
        })
      },
      catchError( this.handleError<any>(`POST /SignIn`)),
    ));
  }

  // Sign out
  SignOut() {
    localStorage.clear();
    localStorage.removeItem('user');
    this.showLoader = false;
    this.router.navigate(['/auth/login']);
  }

  getLoginUrl(){
    var url = `${this.Url}/login?autoRedirect=no`;
    return this.http.get( url, {responseType : 'text'} ).pipe(
      tap( data => {
        console.log(data);
       }),
      catchError( this.handleError<any>(`GET /Login URL`) )
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.apiErrorResult(error)
      // console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  apiErrorResult(error : any){
    // if(error.url.indexOf("https://welcare.suredatalab.kr/dex/auth/default/login") > -1){
    if(error.url.indexOf(`${this.Url}/login`) > -1){
      this.loginErrorMessageSubject.next("ID와 Password를 확인해 주세요.")
    }
  }

}