import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Favorites } from '../data-object/Favorites';
import { Filter } from '../data-object/Filter';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  constructor(
    private http: HttpClient,
  ) { }

    private Url = environment.httpText + environment.apiServer + 'favorites';
//favorites
    getFavorites(filter : Filter) : Observable<Favorites>{ // 
      var url = `${this.Url}`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.largeCategoryName != null){
        httpParams =  httpParams.set('largeCategoryName', filter.largeCategoryName)
      }

      if(filter.mediumCategoryName != null){
        httpParams =  httpParams.set('mediumCategoryName', filter.mediumCategoryName)
      }
  
      return this.http.get<Favorites>( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<Favorites>(`GET /favorites`) )
      );
    }

    createFavorites(categoryCode : string){ // 
      var url = `${this.Url}`;
      let parameter = {
        categoryCode: categoryCode
      }
  
      return this.http.post( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /favorites`) )
      );
    }

    getFavoritesCategory(categoryCode : string){ // 
      var url = `${this.Url}/${categoryCode}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /favorites`) )
      );
    }

    deleteFavoritesCategory(categoryCode : string){ // 
      var url = `${this.Url}/${categoryCode}`;

      let httpParams = new HttpParams().set("categoryCode",categoryCode)

      let parameter = {
        categoryCode: categoryCode
      }

      return this.http.delete( url, {body:parameter} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /favorites`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
