import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
// import { PasswordForgotComponent } from 'src/app/shared/modal/password-forgot/password-forgot.component';
import { SignUpComponent } from 'src/app/shared/modal/sign-up/sign-up.component';
import { AuthService } from '../../shared/services/firebase/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild("signUpModal") signUpModal: SignUpComponent;
  // @ViewChild("passwordForgot") passwordForgot: PasswordForgotComponent;

  // enter 로그인
  @HostListener("document:keydown.enter", ['$event'])

  onKeydownHandler(event: KeyboardEvent) {
    this.login();
  }
  // 
  
  
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  // authService: any;

  // public authService: AuthService,
  constructor(private fb: FormBuilder,public authService: AuthService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  loginErrorMessage$ : Subscription;

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.loginErrorMessage$)this.loginErrorMessage$.unsubscribe()
  }

  ngOnInit() {
    this.loginErrorMessage$ = this.authService.loginErrorMessage$.subscribe(errorMessage =>{
      this.errorMessage = errorMessage
    })
    localStorage.clear()
    localStorage.getItem('user')
  }

  showPassword() {
    this.show = !this.show;
  }

  // Simple Login
  login() {
    console.log("login")

    this.authService.getLoginUrl().subscribe(result=>{
      if(result){
        console.log(result, this.loginForm.value)
        this.authService.SignIn(result, this.loginForm.value['email'], this.loginForm.value['password']).subscribe()
        
      }
    })
  }

  signUp(){
    this.signUpModal.openModal()
  }
  
  // forgetPassword(){
  //   this.passwordForgot.openModal()
  // }
}

