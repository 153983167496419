import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../data-object/User'
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public user : User = new User()


  @ViewChild("signUp", { static: false }) signUp: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService
  ) { }

  ngOnInit(): void {
    
  }

  phoneNumberFirst = ""
  phoneNumberSecond = ""
  phoneNumberThird = ""

  checkEmail : boolean = false;
  checkName : boolean = false;
  checkSurName : boolean = false
  checkUid : boolean = false;
  checkPhoneNumber : boolean = false;
  checkAuthority : boolean = false;

  consentCheckBox1 : boolean = false;
  consentCheckBox2 : boolean = false;
  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.signUp, {
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  application(){
    console.log(this.user)

    let regExp = /^[0-9a-z]([-_.]?[0-9a-z])*@[0-9a-z]([-_.]?[0-9a-z])*.[a-z]{2,3}$/i;

    if(this.user.email.match(regExp) == null){
      this.checkEmail = true
      return
    }else {
      this.checkEmail = false
    }

    if(this.user.username == ""){
      this.checkName = true
      return
    }else {
      this.checkName = false
    }

    if(this.user.surname == ""){
      this.checkSurName = true;
      return
    }else {
      this.checkSurName = false;
    }

    /*if(this.user.uid == ""){
      this.checkUid = true
      return
    }else {
      this.checkUid = false;
    }*/

    if(this.phoneNumberFirst == "" || this.phoneNumberSecond.length < 4 || this.phoneNumberThird.length < 4){
      this.checkPhoneNumber = true
      return
    }else {
      this.checkPhoneNumber = false
    }

    if(this.user.authorityId == ""){
      this.checkAuthority = true
      return
    }else {
      this.checkAuthority = false
    }

    if(!this.consentCheckBox1 || !this.consentCheckBox2){
      alert("약관에 동의해주세요.")
      return
    }

    this.user.contact.extension = this.phoneNumberFirst + "-" + this.phoneNumberSecond + "-" + this.phoneNumberThird



    this.userService.addUserProfile(this.user).subscribe(result=>{
      console.log(result)

      alert("회원가입 신청이 완료되었습니다.")
      this.modalService.dismissAll()
    })
  }
}
