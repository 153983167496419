import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user.service';
import { User } from '../../data-object/User';

@Component({
  selector: 'app-chagne-password',
  templateUrl: './chagne-password.component.html',
  styleUrls: ['./chagne-password.component.scss']
})
export class ChagnePasswordComponent implements OnInit {

  @ViewChild("chagnePassword") chagnePassword: ChagnePasswordComponent;


  public closeResult: string;
  public modalOpen: boolean = false;


  previousPasswordSohw : boolean = false
  newPasswordSohw : boolean = false
  newPasswrodRepeatedShow : boolean = false
  
  previousPassword : string = ""
  newPassword : string = ""
  newPasswrodRepeated : string = ""
  

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService
  ) { }

  ngOnInit(): void {

  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.chagnePassword, { 
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  updatePassword(){

    if(this.previousPassword == ""){
      alert("기존 비밀번호를 입력해주세요.")
      return;
    }

    if(this.newPassword == ""){
      alert("새 비밀번호를 입력해주세요.")
      return
    }

    if(this.newPasswrodRepeated == ""){
      alert("새 비밀번호 확인을 입력해주세요.")
      return
    }

    if(this.newPassword != this.newPasswrodRepeated){
      alert("새 비밀번호와 새 비밀번호 확인이 일치하지 않습니다.")
      return
    }
    
    this.userService.chagneUsersPassword(this.previousPassword, this.newPassword).subscribe(result=>{
    })

  }
}
