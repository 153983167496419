import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjdhYzNjMTI1MTcwYWYwNzQ0MWU2YTZiYjgwOGRkMmEwMDQzNWQ2YzcifQ.eyJpc3MiOiJodHRwczovL25wcGRhdGEuc3VyZWRhdGFsYWIua3IvZGV4Iiwic3ViIjoiQ2dOelpHd1NCMlJsWm1GMWJIUSIsImF1ZCI6InNkbC1hdXRoIiwiZXhwIjoxNjgzODU3NjI3LCJpYXQiOjE2ODM3NzEyMjcsImF0X2hhc2giOiJ0aGd3V3pqejZvd3ZCWVpDX3puYl9RIiwiZW1haWwiOiJtYW5hZ2VyQHN1cmVkYXRhbGFiLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJncm91cHMiOlsiYWRtaW4iXSwibmFtZSI6IuyLnOyKpO2FnCDqtIDrpqzsnpAiLCJmZWRlcmF0ZWRfY2xhaW1zIjp7ImNvbm5lY3Rvcl9pZCI6ImRlZmF1bHQiLCJ1c2VyX2lkIjoic2RsIn19.JejsWsyYbYBOLqB9Q7bN-xkE6L8K1bw8KWJLrGuaYNY5bXNUoGmQGOl8h44TPpoPgLdCuObCdie1HhKL6y8U8S5qK7w4YR5-GzEpxORjzVuOkr3lATS2cI3NPR0dTSz5GPo_ivSKdLB5e3KeaDt7VR_gIS2ne7MSuKy-7z6dhW9U1wWI8LUCY0u-DgarFOknWckaVzYpuSlPtMQte-ds_j9nV4KbN_a82nRJH8x4JDEFxcTYX82BKnRfhWIABItufQh98_-S8GeneqHwGCWa0Qse4qGknkLd-wNi5jj9T5wOQKw1FPpMBX9mdW7d7tZLotXh1cVflwkpOYgX3UAlnQ"

    const authReq = req.clone({
      headers: req.headers.set('Authorization', token)
    });

    return next.handle(authReq)
  }
}