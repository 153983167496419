import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { UserService } from '../../services/user.service';
import { Filter } from '../../data-object/Filter';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  @ViewChild("passwordForgot", { static: false }) passwordForgot: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  email : string = "";

  @Output() forgotEmail = new EventEmitter<string>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private userService : UserService
  ) { }

  check : number = 0;

  ngOnInit(): void {
    
  }

  openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.passwordForgot, {
        size: 'lg',
        ariaLabelledBy: 'modal',
        centered: true,
        windowClass: 'modal'
      }).result.then((result) => {
        this.modalOpen = true;
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  checkEmail(){

    let filter : Filter = new Filter()
    filter.email = this.email

    this.userService.addUsers(filter).subscribe(result=>{
      if(result){
        this.check = 1
      }else {
        this.check = 2
      }
    })
  }

  changeEmail(){
    this.check = 0
  }

  forgot(){

    if(this.check == 0){
      alert("E-mail을 먼저 확인해 주세요.")
      return
    }

    if(this.check == 2){
      alert("E-mail이 존재하지 않습니다.")
      return
    }

    if(this.email == ""){
      alert("E-mail을 입력해주세요.")
      return
    }
    
    console.log(this.email)

    this.userService.getUsersForgot(this.email).subscribe(result=>{
      
      if(result){
        //this.modalService.dismissAll()
      }else {
        this.check = 1
      }

    })

    
  }

}
