import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  {
    path: 'dataSearch',
    loadChildren: () => import('../../components/dataSearch/data-search.module').then(m => m.DataSearchModule)
  },

  {
    path: 'correlationAnalysis',
    loadChildren: () => import('../../components/correlation-analysis/correlation-analysis.module').then(m => m.CorrelationAnalysisModule)
  },

  {
    path: 'dataRegistration',
    loadChildren: () => import('../../components/data-registration/data-registration.module').then(m => m.DataRegistrationModule)
  },

  {
    path: 'dataManagement',
    loadChildren: () => import('../../components/data-management/data-management.module').then(m => m.DataManagementModule)
  },

  {
    path: 'monitoring',
    loadChildren: () => import('../../components/monitoring/monitoring.module').then(m => m.MonitoringModule)
  },

  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule)
  },
];

