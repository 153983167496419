<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-image">
        <img  src="../../../assets/images/do-messenger_screenshot_2023-04-25_21_35_40.png" alt="looginpage">
      </div>
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink='/'>
              <img  class="img-fluid" src="" alt="">
              <!--<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">-->
            </a>
          </div>
          <div class="login-main"> 
            <form class="theme-form" [formGroup]="loginForm">
              <div class="form-group">
                <label class="col-form-label">Email Address</label>
                <input class="form-control" type="email" required="" placeholder="app@yourmail.com" formControlName="email">
                  <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                      class="text text-danger mt-1">
                      Email is required
                  </div>
                  <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                      class="text text-danger mt-1">
                      Invalid Email
                  </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********">
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                      class="text text-danger mt-1">
                      Password is required
                </div>
              </div>
            
              <div class="error-message-box">
                <div class="error-message" *ngIf="errorMessage"> {{errorMessage}} </div>
              </div>

              <div class="form-group mb-0">
                <!-- <div class="checkbox p-0">
                  <input id="checkbox1" type="checkbox">
                  <label class="text-muted" for="checkbox1">Remember password</label>
                </div> -->
                <!-- <a class="link" style="cursor: pointer;">비밀번호 찾기</a> -->
                <button class="btn btn-primary d-block w-100"
                  [class.loader--text]="authService.showLoader"
                  [disabled]="!loginForm.valid || authService.showLoader"
                  (click)="login()"
                  type="button"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
                </button>
              </div>
              <!-- <p class="mt-4 mb-0">
                <a class="ms-2" style="cursor: pointer;" (click)="signUp()">회원가입</a>
              </p> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-sign-up #signUpModal></app-sign-up>
<app-password-forgot #passwordForgot></app-password-forgot>