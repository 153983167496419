import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { execFile } from 'child_process';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  
  constructor(
    public authService: AuthService,
    public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guard for user is login or not
    
    let user = JSON.parse(localStorage.getItem('user'));
    console.log("user")
    // debugger
    if (!user || user === null) {
      this.router.navigate(['/auth/login']);
      return true
    } else if (user) {
      if (!Object.keys(user).length) {
        this.router.navigate(['/auth/login']);
        return true
      }

      if(user.authorityId == "root"){

        // 임시로 dashboard 화면으로 이동 제한 -> 데이터 등록 페이지로 이동하게 
        // if(state.url.indexOf('/dashboard/manager') < 0 && 
        if(state.url.indexOf('/dataRegistration') < 0 && 
        state.url.indexOf('/dataSearch') < 0 &&
        state.url.indexOf('/correlationAnalysis') < 0 &&
        state.url.indexOf('/dataRegistration') < 0 &&
        state.url.indexOf('/dataManagement/purchase_information') < 0 &&
        state.url.indexOf('/dataManagement/data') < 0 &&
        state.url.indexOf('/monitoring/cluster') < 0 &&
        state.url.indexOf('/monitoring/data') < 0 &&
        state.url.indexOf('/users/sign_up_approval') < 0 &&
        state.url.indexOf('/users/management') < 0 
        ){
          // this.router.navigate(['/dashboard/manager']);
          this.router.navigate(['dataRegistration']);
          return true
        }

      }else if(user.authorityId == "user"){

        // if(state.url.indexOf('/dashboard/guest') < 0 && 
        if(state.url.indexOf('/dataRegistration') < 0 && 
        state.url.indexOf('/dataSearch') < 0 &&
        state.url.indexOf('/correlationAnalysis') < 0
        ){
          // this.router.navigate(['/dashboard/guest']);
          this.router.navigate(['dataRegistration']);
          return true
        }

      }
    }

    return true
  }
  
}
