import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CorellationsService {

  constructor(
    private http: HttpClient,
  ) { }
  
    private Url = environment.httpText + environment.apiServer + 'corellations';

    
    getCorellations(filter : Filter){ // 상관관계 분석 목록 조회
      var url = `${this.Url}`;
      let httpParams = new HttpParams()

      if(filter.asc != null){
        httpParams = httpParams.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        httpParams = httpParams.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        httpParams = httpParams.set('limit',filter.limit)
      }

      if(filter.offset != null){
        httpParams = httpParams.set('offset', filter.offset)
      }

      if(filter.name != null){
        httpParams = httpParams.set('name', filter.name)
      }
  
      return this.http.get( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /corellations`) )
      );
    }

    getCalculateCorellation(subjectA : any, subjectB : any){ // 상관관계 분석 수행
      var url = `${this.Url}/analysis`;
      let httpParams = new HttpParams()
      .set("subjectA",subjectA.subjectId)
      .set("subjectB",subjectB.subjectId)
  
      return this.http.get( url, {params : httpParams} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /corellations/analysis`) )
      );
    }

    getCorellationsHistory(corellationId : string){ // 상관관계 분석 내역 조회
      var url = `${this.Url}/history/${corellationId}`;

      return this.http.get( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /corellations/history/{corellationId}`) )
      );
    }

    deleteCorellations(corellationId : string){ // 상관관계 분석 내역삭제
      var url = `${this.Url}/${corellationId}`;

      return this.http.delete( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /corellations/{corellationId}`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
