import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }


  getDateTimeFormat(date : Date){
    let dateTime : string = ""
    dateTime = date.getFullYear() + "-"
    if(date.getMonth() < 9){
      dateTime += "0"
    }
    dateTime += date.getMonth() + "-"

    if(date.getDay() < 9){
      dateTime += "0"
    }
    dateTime += date.getDay() + " "

    if(date.getHours() < 9){
      dateTime += "0"
    }

    dateTime += date.getHours() + ":"

    if(date.getMinutes() < 9){
      dateTime += "0"
    }

    dateTime += date.getMinutes() + ":"

    if(date.getSeconds() < 9){
      dateTime += "0"
    }

    dateTime += date.getSeconds()

    return dateTime
  }

  setDateTime(date : any, time : any){
    let dateTime : string
    dateTime = date.year + "-"

    if(date.month < 10){
      dateTime += "0"
    }
    dateTime += date.month + "-"

    if(date.day < 10){
      dateTime += "0"
    }

    dateTime += date.day + " "

    if(!time.hour || !Number(time.hour)){
      time.hour = 0
    }

    if(time.hour < 10){
      dateTime += "0"
    }

    dateTime += time.hour + ":"

    if(!time.minute || !Number(time.minute)){
      time.minute = 0
    }

    if(time.minute < 10){
      dateTime += "0"
    }

    dateTime += time.minute + ":"

    if(!time.second || !Number(time.second)){
      time.second = 0
    }

    if(time.second < 10){
      dateTime += "0"
    }
    dateTime += time.second

    return dateTime

  }

}
