import { Component, OnInit, ViewChild } from '@angular/core';
import { UserAccountComponent } from 'src/app/shared/modal/user-account/user-account.component';
import { AuthService } from 'src/app/shared/services/firebase/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public user : any = {name : ""}
  public profileImg: 'assets/images/dashboard/profile.jpg';

  @ViewChild("userAccount") userAccount: UserAccountComponent;

  constructor(public authService: AuthService,
    private userService : UserService) {

      
    this.user = JSON.parse(localStorage.getItem('user'))
  }

  myAccount(){
    this.userAccount.openModal()
  }


  logoutFunc() {
    this.authService.SignOut();
  }

  ngOnInit() {
  }

}
