export class Filter{
    asc : string[]
    desc : string[]
    limit : number
    offset : number 
    categoryCode : string
    patientId : string
    isAttached : boolean
    begin : string
    end : string

    name : string
    largeCategoryName : string
    mediumCategoryName : string
    providerName : string

    code : string

    subjectName : string
    subjectCode : string

    email : string
    authority : string
    attributeName : string
    attributeValue : string
    status : string[]


    fileId: string
    originName: string
    currentName: string
    comment: string
    createdUserId: string
    createdAt: string
}