<ng-template #passwordForgot let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">비밀번호 찾기</h5>
        <button class="btn-close" type="button" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <form class="form-bookmark needs-validation" id="bookmark-form" novalidate="">

          <div class="row">
            <div class="form-group col-md-12">
                <label for="eMail"> E-mail </label>
                <input class="form-control" id="eMail" type="text" [(ngModel)]="email" (change)="changeEmail()">
                <div *ngIf="check == 1" class="invalid-feedback">E-Mail 전송에 실패하였습니다. E-Mail을 확인해주세요.</div>
                <div *ngIf="check == 2" class="invalid-feedback">E-Mail이 확인되었습니다.</div>

            </div>

            <!--<div class="form-group col-md-3">
              <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="checkEmail()">이메일 확인</button>    
            </div>  
          -->

          <div class="col-md-12" >
              <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100px;">
                <img src="../../../../assets/images/email_image.png" style="object-fit: cover; width: 100px; height: 100px;">
              </div>
              <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                <h6>비밀번호 초기화 버튼을 누르시면 해당 메일로 비밀번호 변경을 위한 링크가 전송됩니다.</h6>
              </div>
          </div>

          
          </div>

          <button class="btn btn-primary" style="float: right;" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">닫기</button>
          <button class="btn btn-primary" style="float: right; margin-right: 10px;" type="button" data-dismiss="modal" (click)="forgot()()">비밀번호 초기화</button>
        </form>
    </div>

</ng-template>