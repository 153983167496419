import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fsyncSync } from 'fs';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Filter } from '../data-object/Filter'

import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ResearchdataService {

  listSort : string = 'desc'

  constructor(
    private http: HttpClient,
    private router: Router 
  ) { }
  private Url = environment.httpText + environment.apiServer;

    getResearchData(filter : Filter){
      var url = `${this.Url}datacollections/files`
      let params = new HttpParams()
      
      params = params.set(this.listSort, 'FILE_ID')

      if(filter.asc != null){
        params = params.set('asc', filter.asc.join(', '))
      }

      if(filter.desc != null){
        params = params.set('desc', filter.desc.join(', '))
      }

      if(filter.limit != null){
        params = params.set('limit', filter.limit)
      }

      if(filter.offset != null){
        params = params.set('offset', filter.offset)
      }

      // if(filter.categoryCode != null){
      //   params = params.set('categoryCode', filter.categoryCode)
      // }

      // if(filter.patientId != null){
      //   params = params.set('patientId', filter.patientId)
      // }

      // if(filter.isAttached != null){
      //   params = params.set('isAttached', filter.isAttached)
      // }

      // if(filter.begin != null){
      //   params = params.set('begin', filter.begin)
      // }

      // if(filter.end != null){
      //   params = params.set('end', filter.end)
      // }

      return this.http.get<any>( url, {params : params} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /api/datacollections/files`) )
      );
    }

    getlistsort(filter :Filter) {
      var url = `${this.Url}datacollections/files`
      let params = new HttpParams()

      // sort
      if(this.listSort !== 'desc') {
        this.listSort = 'desc'
      } else {
        this.listSort = 'asc'
      }

      params = params.set(this.listSort, 'FILE_ID')

      if(filter.limit != null){
        params = params.set('limit', filter.limit)
      }
      
      if(filter.offset != null){
        params = params.set('offset', filter.offset)
      }

      return this.http.get<any>( url, {params : params} ).pipe(
        tap( data => {
          console.log(data);
         }),
      );
    }

    addResearchData(meta : any){ // 연구데이터 추가
      var url = `${this.Url}/researchdata`
      let parameter = meta
  
      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /researchdata`) )
      );
    }

    getDownloadResearchData(categoryCode : string){ // 연구데이터 다운로드 보유한 데이터만 다운로드 가능
      var url = `${this.Url}/download/${categoryCode}`

      return this.http.get<any>( url).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /researchdata/download/{categoryCode}`) )
      );
    }

    getSampleData(categoryCode : string){ // 카테고리에 해당하는 샘플 데이터 조회
      var url = `${this.Url}/sample/${categoryCode}`

      return this.http.get<any>( url).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /researchdata/sample/{categoryCode}`) )
      );
    }

    fileUpload(fileName: string, fileContent: any) {
        const uploadUrl = `${this.Url}datacollections/upload`; // 업로드할 URL
        const binary = fileContent
        const headers = new HttpHeaders({
          'filename': encodeURIComponent(fileName.toString().normalize('NFC')), //파일명이 한글일경우
          'Content-Type': fileContent.type
        });
  
        console.log("upload file: ", fileContent)
        this.http.post(uploadUrl, binary, { headers }).subscribe(
          (response) => {
            console.log(response)
            if (response) {
              alert('업로드에 성공 하였습니다.')
            } else {
              alert('업로드에 실패 하였습니다.')
            }
          }, (error) => {
            // 에러 처리 로직
            console.log('HTTP POST 요청 에러:', error)
            alert(`(${error.status}) ${error.statusText}, 업로드에 실패하였습니다.`)
          }
        );
    }


    getDatas() {
      return this.http.get(`${this.Url}datacollections/files`).pipe(
        catchError((error: HttpErrorResponse) => {
          console.log(error.statusText);
          if(error.status == 401) {
            this.router.navigate(['/auth/login'])
          }
          return throwError(error);
        })
      );
    }
    
    deleteDocumentResearchData(fileId : string){ 
      var url = `${this.Url}datacollections/${fileId}`
      console.log(fileId)
      return this.http.delete<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /researchdata/{fileId}`) )
      );
    }

    updatedDocumentResearchData(meta : any){ 
      var url = `${this.Url}/${meta.documentId}`
      let parameter = {
        data : meta
      }

      return this.http.put<any>( url, JSON.stringify(parameter), httpOptions).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`PUT /researchdata/{documentId}`) )
      );
    }

    getDocumentResearchData(fileId : string){ // 연구데이터 조회
      var url = `${this.Url}/${fileId}`
  
      return this.http.get<any>( url ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`GET /researchdata/{fileId}/attachments`) )
      );
    }

    deleteAttachFile(documentId : string, uuid:string[]){ 
      var url = `${this.Url}/${documentId}/attachments`
      let params = new HttpParams()

      if(uuid != null){
        params = params.set("uuid",uuid.join(', '))
      }

      return this.http.delete<any>( url, {params : params} ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`DELETE /researchdata/{documentId}/attachments`) )
      );
    }

    uploadAttachFile(documentId : string, originFileName:string, contents:string){
      var url = `${this.Url}/${documentId}/attachments`

      let parameter = {
        originFileName: originFileName,
        contents: contents
      }

      return this.http.post<any>( url, JSON.stringify(parameter), httpOptions ).pipe(
        tap( data => {
          console.log(data);
         }),
        catchError( this.handleError<any>(`POST /researchdata/{documentId}/attachments`) )
      );
    }

    private log(message: string) {
      console.log(message)  
    }
  
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
      };
    }

}
